import React, { useEffect } from 'react';
import { Link } from 'gatsby';

import Seo from '../components/layout/Seo';
import Layout from '../components/layout/Layout';
import Awards from '../components/lists/Awards';
import MediaCoverage from '../components/lists/MediaCoverage';
import Publications from '../components/lists/Publications';
import SpeakingEngagements from '../components/lists/SpeakingEngagements';
import SupportStaff from '../components/lists/SupportStaff';

const AboutPage = () => {
  useEffect(() => {
    window.location.hash = '#';
  }, []);

  const handleTabClick = (tab: string) => {
    const activeTabName = tab;
    const activeTab = document.getElementById(activeTabName);
    let position = activeTab?.getBoundingClientRect();
    if (position !== undefined) {
      window.scrollTo(position.left, position.top + window.scrollY - 80);
    }
    handleActiveTabClass(activeTabName);
  };

  const handleTopTabClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    const activeTabName = (event.target as HTMLAnchorElement).href.split(
      '#'
    )[1];
    handleActiveTabClass(activeTabName);
  };

  const handleActiveTabClass = (activeTabName: string) => {
    const tabs = document.getElementsByClassName('sub-tab');
    const tabsArray = Array.from(tabs);
    tabsArray.forEach(tab => {
      if (tab.ariaLabel === activeTabName) {
        tab.className =
          'inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 border-b-2 cursor-pointer hover:no-underline sub-tab border-primary';
      } else {
        tab.className =
          'inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 border-b-2 border-transparent cursor-pointer sub-tab hover:no-underline hover:border-gray-300 hover:text-gray-700';
      }
    });
  };

  return (
    <Layout>
      <Seo title='About Adam Langino' />

      <div className='max-w-6xl p-8 mx-auto sm:mx-auto md:p-0 md:mt-4'>
        {/* Subnav */}
        <nav className='bg-white'>
          <div className='flex justify-center mx-auto max-w-7xl'>
            <div className='flex justify-between'>
              <div className='px-0 text-center'>
                <div className='grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-6 justify-items-center'>
                  <a
                    href='#adam-langino'
                    className='inline-flex items-center px-1 pt-1 text-sm font-medium text-center text-gray-900 border-b-2 hover:no-underline sub-tab border-primary'
                    onClick={handleTopTabClick}
                  >
                    Adam Langino
                  </a>
                  <span
                    aria-label='awards'
                    className='inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 border-b-2 border-transparent cursor-pointer sub-tab hover:no-underline hover:border-gray-300 hover:text-gray-700'
                    onClick={() => handleTabClick('awards')}
                  >
                    Awards
                  </span>
                  <span
                    aria-label='media-coverage'
                    className='inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 border-b-2 border-transparent cursor-pointer sub-tab hover:no-underline hover:border-gray-300 hover:text-gray-700'
                    onClick={() => handleTabClick('media-coverage')}
                  >
                    Media Coverage
                  </span>
                  <span
                    aria-label='publications'
                    className='inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 border-b-2 border-transparent cursor-pointer sub-tab hover:no-underline hover:border-gray-300 hover:text-gray-700'
                    onClick={() => handleTabClick('publications')}
                  >
                    Publications
                  </span>
                  <span
                    aria-label='speaking-engagements'
                    className='inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 border-b-2 border-transparent cursor-pointer sub-tab hover:no-underline hover:border-gray-300 hover:text-gray-700'
                    onClick={() => handleTabClick('speaking-engagements')}
                  >
                    Speaking Engagements
                  </span>
                  <span
                    aria-label='support-staff'
                    className='inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 border-b-2 border-transparent cursor-pointer sub-tab hover:no-underline hover:border-gray-300 hover:text-gray-700'
                    onClick={() => handleTabClick('support-staff')}
                  >
                    Support Staff
                  </span>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* End Subnav */}

        <div className='flex flex-col pb-10 border-b-2' id='adam-langino'>
          <div className='flex flex-col justify-center md:flex-row'>
            <div className='flex w-full mt-5 md:justify-center md:w-1/2'>
              <div className='bg-dots'>
                <div className='z-10 max-w-md mt-6 rounded-full shadow-2xl'>
                  <img
                    alt='card img'
                    className='rounded'
                    src='/images/adam/langino-family.jpeg'
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-col justify-center max-w-md md:w-1/2'>
              <div className='mt-6 md:ml-10 text-md dark-text'>
                <p>
                  Adam was born and raised in New York and earned his
                  undergraduate degree in political science from the University
                  of Maryland. After graduation, he attended law school at the
                  University of Minnesota in Minneapolis, where he
                  simultaneously competed as an amateur boxer. Developing skills
                  as a fighter required discipline, focus, and resilience -
                  traits that have been assets in his professional life ever
                  since.
                </p>
                <p>
                  Adam moved to West Palm Beach, FL after law school and worked
                  as an assistant public defender, a job he took out of a
                  fervent belief that justice belongs not just to the rich and
                  powerful, but to everyone. Over the next three years, Adam
                  gained valuable experience as a trial attorney handling
                  complex, high stakes cases.
                </p>
                <p>
                  The transition to plaintiff's law was a natural next step. As
                  a plaintiff's attorney, Adam has spent over a decade
                  representing ordinary people who have been harmed by the
                  carelessness and greed of large corporations. He worked at a
                  prestigious national law firm with offices across several
                  major U.S. cities, and served as primary attorney on a variety
                  of catastrophic injury and wrongful death cases. His verdicts
                  and settlements total over $25 million, and he considers it an
                  honor and his professional duty to help people hold large
                  corporations accountable.
                </p>
              </div>
            </div>
          </div>

          <div className='flex flex-col justify-center w-full'>
            <div className='mt-6 md:ml-10 text-md dark-text'>
              <p>
                In 2018, Adam and his wife moved to Chapel Hill, NC with their
                three young children.
              </p>
              <p>
                Since then, Adam has continued to work on cases in North
                Carolina, Florida, and throughout the country. In early 2022, he
                decided the time was right to establish Langino Law, PLLC.
                Owning his law practice affords Adam independence over his cases
                and the ability to forge meaningful connections with his
                clients, most of whom are referred to him by a nationwide
                network of friends and colleagues from a wide variety of legal
                practice areas.
              </p>
              <p>
                If you&apos;d like to reach him for a free case evaluation or
                referral, please<Link to='/contact'> click here</Link>.
              </p>
              <p>
                If you&apos;d like to learn more about his philosophy toward the
                practice of law, please
                <Link to='/article/what-makes-a-good-attorney'>
                  {' '}
                  click here
                </Link>
                .
              </p>

              <div className='flex justify-center mt-6'>
                <img
                  alt='card img'
                  className='max-w-sm mx-auto rounded shadow-2xl'
                  src='/images/adam/langino-boxing.jpg'
                />
              </div>
            </div>
          </div>
        </div>

        <span className='border-b-2' id='awards' />
        <Awards />

        <span className='border-b-2' id='media-coverage' />
        <MediaCoverage />

        <span className='border-b-2' id='publications' />
        <Publications />

        <span className='border-b-2' id='speaking-engagements' />
        <SpeakingEngagements />

        <span className='border-b-2' id='support-staff' />
        <SupportStaff />
      </div>
    </Layout>
  );
};

export default AboutPage;
