import React, { useState, useEffect } from 'react';

import { Award } from '../../types/Award';
import { awardsTitle, awardsData } from '../../data/awardsData';

const Awards = () => {
  const [title, setTitle] = useState('');
  const [awards, setAwards] = useState<Award[]>([]);

  useEffect(() => {
    setTitle(awardsTitle);
    setAwards(awardsData);
  }, []);

  return (
    <div className='flex flex-col my-5 mt-10'>
      <h1 className='py-4 text-center font-slim text-primary'>{title}</h1>
      {awards.map((award, index) => (
        <div key={index}>
          <h3 className='dark-text head-3'>{award.title}</h3>
          <p className='text-md dark-text lg:pl-16'>{award.summary}</p>
        </div>
      ))}
    </div>
  );
};

export default Awards;
