import { SpeakingEngagement } from '../types/SpeakingEngagement';

export const speakingEngagementsTitle = 'Speaking Engagements';

export const speakingEngagementsData: SpeakingEngagement[] = [
  {
    title: 'Arbitration, Presenter, American Association for Justice',
    summary: `In 2024, Adam Langino presented nationally to attorneys across the U.S. concerning the benefits and downsides to arbitrating product liability claims against major foreign manufacturers.`,
  },
  {
    title: 'Product Liability, Moderator, American Association for Justice',
    summary: `In 2023, at AAJ's Annual Convention in Philadelphia, PA, Adam Langino
    organized and moderated a full-day CLE for national group of product
    liability attorneys.`,
  },
  {
    title:
      'Complex Litigation, Presenter, North Carolina Advocates for Justice',
    summary: `In 2022, Adam Langino presented to North Carolina attorneys concerning
    tips on handling catastrophic injury claims for minor children.`,
  },
  {
    title: 'Child Safety, Presenter, American Association for Justice',
    summary: `In 2022, Adam Langino presented nationally to attorneys across the U.S.
    concerning defective child seats and how to successfully prosecute claims
    involving injured children.`,
  },
  {
    title: 'Catastrophic Injury, Presenter, North Carolina State Bar',
    summary: `In 2021, Adam Langino presented to North Carolina attorneys concerning how
    to handle catastrophic injury claims.`,
  },
  {
    title: 'Guardrail Litigation, Presenter, Strafford Publications',
    summary: `In 2021, Adam Langino presented to attorneys across the U.S. tips on
    identifying guardrail defects and how to prosecute catastrophic injury
    claims.`,
  },
  {
    title: 'Child Safety, Presenter, American Association for Justice',
    summary: `In 2020, Adam Langino presented nationally to attorneys across the U.S.
    concerning emerging issues in child safety and tips on handling claims
    involving injured children.`,
  },
  {
    title: 'Child Seat Defects, Presenter, American Association for Justice',
    summary: `In 2020, at AAJ's Annual Convention in Chicago, IL, Adam Langino presented
    nationally to attorneys across the U.S. concerning how to handle claims
    where there is a defect in a child's booster or five-point harness safety
    seat.`,
  },
  {
    title: 'Federal Databases, Presenter, AIEG',
    summary: `In 2019, at AIEG's Summer conference in Sun Valley, ID, Adam Langino
    presented to attorneys across the U.S. on how to use federal databases to
    find helpful information to prosecute complex claims.`,
  },
  {
    title: 'Police Abuse, Presenter, American Association for Justice',
    summary: `In 2018, at AAJ's Annual Convention in Denver, CO, Adam Langino presented
    to attorneys across the U.S. tips for reporting police abuse and key steps
    to take in prosecuting police abuse claims.`,
  },
  {
    title: 'Electronic Discovery, Presenter, Thompson Reuters',
    summary: `In 2017, Adam Langino presented to attorneys across the U.S. on key things
    to remember when working with electronically stored information.`,
  },
  {
    title:
      'Electronic Preservation, Presenter, American Association for Justice',
    summary: `In 2017, Adam Langino presented to attorneys across the U.S. on how to
    handle electronic preservation requests and how to seek electronic
    information.`,
  },
  {
    title: 'Expert Witnesses, Presenter, National Trial Lawyers Summit',
    summary: `In 2017, at the National Trial Lawyers Summit in Miami, FL, Adam Langino
    presented to attorneys across the U.S. on how to prepare expert witnesses
    for cross-examination and how to defend against expert challenges.`,
  },
];
