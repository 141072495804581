import { Publication } from '../types/Publication';

export const publicationsTitle = 'Publications';

export const publicationsData: Publication[] = [
  {
    publication: `“Keep Your Cases Moving Forward,” AAJ Trial Magazine`,
    author: 'Adam J. Langino, Esq.',
    content: `The American Association for Justice's “Trial Magazine” is AAJ's award-winning flagship publication for attorneys, law professors, judges, and other members of the legal community. Adam Langino's article, “Keep Your Cases Moving Forward,” was published in October 2024 discusses how attorneys can effectively manage their practice and keep their claims moving forward to a successful resolution.`,
  },
  {
    publication: `“A Gap in Child Seat Safety,” FJA Journal`,
    author: 'Adam J. Langino, Esq.',
    content: `The Florida Justice Association's “Journal” is the voice of authority on
        legal trends and case law updates for Florida plaintiff lawyers. Adam
        Langino's article, “A Gap in Child Seat Safety,” was published in FJA
        Journal Issue 592 and evaluates the best practices for using booster seats
        with children and explains the safety issues with how the child seat
        industry markets booster seats.`,
  },
  {
    publication: `“Accountability Across Borders,” CTLA Trial Talk, AAJ Trial Magazine`,
    author: 'Adam J. Langino, Esq.',
    content: `The American Association for Justice's “Trial Magazine” is AAJ's
        award-winning flagship publication for attorneys, law professors, judges,
        and other members of the legal community. Adam Langino's article,
        “Accountability Across Borders,” was published in AAJ's Trial Magazine
        Volume 55, No. 11 and discusses the legal challenges in holding foreign
        product manufacturers accountable for causing harm in the U.S.`,
  },
  {
    publication: `“Amazon: Earth's Most Customer-Centric Company?” FJA Journal`,
    author: 'Adam J. Langino, Esq.',
    content: `The Florida Justice Association's “Journal” is the voice of authority on
        legal trends and case law updates for Florida plaintiff lawyers. Adam
        Langino's article, “Amazon: Earth's Most Customer-Centric Company?,” was
        published in FJA Journal Issue 616 and discusses the legal issues
        surrounding holding Amazon accountable for selling dangerous products.`,
  },
  {
    publication: `“Blind Spots in Booster Seats,” AAJ Trial Magazine`,
    author: 'Adam J. Langino, Esq.',
    content: `The American Association for Justice's “Trial Magazine” is AAJ's
        award-winning flagship publication for attorneys, law professors, judges,
        and other members of the legal community. Adam Langino's article, “Blind
        Spots in Booster Seats,” was published in January 2018 discusses the
        booster seat's industry wrongful practices in marketing booster seats as
        safe for younger or smaller children.`,
  },
  {
    publication: `"Sexual Battery - A Civil Dilemma," FJA Journal`,
    author: 'Adam J. Langino, Esq.',
    content: `The Florida Justice Association's “Journal” is the voice of authority on
        legal trends and case law updates for Florida plaintiff lawyers. Adam
        Langino's article, "Sexual Battery - A Civil Dilemma," was published in
        FJA Journal Issue 550 and discusses how Florida's laws fails to protect
        child victims from their assailants in civil litigation.`,
  },
  {
    publication: `“Child Safety,” FJA Journal`,
    author: 'Adam J. Langino, Esq.',
    content: `The Florida Justice Association's “Journal” is the voice of authority on
        legal trends and case law updates for Florida plaintiff lawyers. Adam
        Langino's article, “Child Safety,” was published in FJA Journal Issue 609
        and discusses the dangers associated with incline infant sleepers.`,
  },
  {
    publication: `“Choking Hazards,” FJA Journal`,
    author: 'Adam J. Langino, Esq.',
    content: `The Florida Justice Association's “Journal” is the voice of authority on
        legal trends and case law updates for Florida plaintiff lawyers. Adam
        Langino's article, “Choking Hazards,” was published in FJA Journal Issue
        620 and discusses what the federal government defines as a choking hazard
        and the science behind that decision.`,
  },
  {
    publication: `“Dangerous Toys,” FJA Journal`,
    author: 'Adam J. Langino, Esq.',
    content: `The Florida Justice Association's “Journal” is the voice of authority on
        legal trends and case law updates for Florida plaintiff lawyers. Adam
        Langino's article, “Dangerous Toys” was published in FJA Journal Issue 596
        and discusses several recent toys that were recalled and the reasons for
        each recall.`,
  },
  {
    publication: `“E-Cigarettes: An Exploding Market,” FJA Journal`,
    author: 'Adam J. Langino, Esq.',
    content: `The Florida Justice Association's “Journal” is the voice of authority on
        legal trends and case law updates for Florida plaintiff lawyers. Adam
        Langino's article, “E-Cigarettes: An Exploding Market,” published in FJA
        Journal Issue 606 discusses the dangers of lithium-ion batteries being
        used in vaping devices and explains why vaping explosions cause
        significant injuries.`,
  },
  {
    publication: `"Ethanol-Based Fuels May Suggest High Blood Alcohol Level in Client
        Automobile Crashes," FJA Journal`,
    author: 'Adam J. Langino, Esq.',
    content: `The Florida Justice Association's “Journal” is the voice of authority on
        legal trends and case law updates for Florida plaintiff lawyers. Adam
        Langino's article “Ethanol-Based Fuels May Suggest High Blood Alcohol
        Level in Client Automobile Crashes," published in FJA Journal Issue 574
        discusses how ethanol fuels may artificially increase an injured person's
        blood alcohol level in fuel-fed fires.`,
  },
  {
    publication: `“Florida's Autonomous Vehicle Law - 2016: First State to Legalize Fully
        Self-Driving Cars,” FJA Journal`,
    author: 'Adam J. Langino, Esq.',
    content: `The Florida Justice Association's “Journal” is the voice of authority on
        legal trends and case law updates for Florida plaintiff lawyers. Adam
        Langino's article, “Florida's Autonomous Vehicle Law - 2016: First State
        to Legalize Fully Self-Driving Cars,” published in FJA Journal Issue 594
        discusses how Florida was one of the first states to allow self-driving
        cars on its roadways and the safety implications associated with such
        activity.`,
  },
  {
    publication: `“Inclined Infant Sleepers Banned,” Attorney At Law Magazine`,
    author: 'Adam J. Langino, Esq.',
    content: `Attorney at Law Magazine is a national online and local print publication about and for the private practice lawyer and the legal community Adam Langino's article, “Inclined Infant Sleepers Banned” published in Attorney At Law Magazine North Carolina Triangle Vol. 11 No. 6, discusses the dangers with inclined sleepers and the federal government’s recent ban of inclined sleepers with an incline sleep surface greater than 10 degrees.`,
  },
  {
    publication: `“Recovery for Injured Workers Outside Florida's Workers' Compensation
        System,” FJA Journal`,
    author: 'Adam J. Langino, Esq.',
    content: `The Florida Justice Association's “Journal” is the voice of authority on
        legal trends and case law updates for Florida plaintiff lawyers. Adam
        Langino's article, “Recovery for Injured Workers Outside Florida's
        Workers' Compensation System,” published in FJA Journal Issue 602
        discusses when employees can attempt to hold their employers or third
        parties accountable for causing their injury.`,
  },
  {
    publication: `“Rethinking Florida's Statute of Repose for Automotive Product Liability,”
        FJA Journal`,
    author: 'Adam J. Langino, Esq.',
    content: `The Florida Justice Association's “Journal” is the voice of authority on
        legal trends and case law updates for Florida plaintiff lawyers. Adam
        Langino's article, “Rethinking Florida's Statute of Repose for Automotive
        Product Liability,” published in FJA journal Issue 604 discusses how
        Florida's statute of repose may no longer protect consumers from defective
        automobiles because of the increasing average age of vehicles on the road.`,
  },
  {
    publication: `“Talcum Powder: Link to Ovarian Cancer,” FJA Journal`,
    author: 'Adam J. Langino, Esq.',
    content: `The Florida Justice Association's “Journal” is the voice of authority on
        legal trends and case law updates for Florida plaintiff lawyers. Adam
        Langino's article, “Talcum Powder: Link to Ovarian Cancer,” was published
        in FJA Journal Issue 590 and discusses how studies are linking talcum
        powder use to ovarian cancer and the legal implications for injured
        consumers.`,
  },
  {
    publication: `“Victim of Its Own Success? Trifecta of Product Liability Trouble Befalls
        Peloton,” FJA Journal`,
    author: 'Adam J. Langino, Esq.',
    content: `The Florida Justice Association's “Journal” is the voice of authority on
        legal trends and case law updates for Florida plaintiff lawyers. Adam
        Langino's article, “Victim of Its Own Success? Trifecta of Product
        Liability Trouble Befalls Peloton,” published in FJA Journal Issue 622
        discusses the safety defects in Peloton's treadmill products and explains
        the legal implications for injured consumers.`,
  },
];
