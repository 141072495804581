import React, { useState, useEffect } from 'react';

import { Media } from '../../types/Media';
import {
  mediaCoverageTitle,
  mediaCoverageData,
} from '../../data/mediaCoverageData';

const MediaCoverage = () => {
  const [title, setTitle] = useState('');
  const [mediaCoverage, setMediaCoverage] = useState<Media[]>([]);

  useEffect(() => {
    setTitle(mediaCoverageTitle);
    setMediaCoverage(mediaCoverageData);
  }, []);

  return (
    <div className='flex flex-col my-5 mt-10'>
      <h1 className='py-4 text-center font-slim text-primary'>{title}</h1>

      {mediaCoverage.map((item, index) => (
        <div key={index}>
          <h3 className='dark-text head-3'>{item.title}</h3>
          <p className='text-md dark-text lg:pl-16'>{item.description}</p>
        </div>
      ))}
    </div>
  );
};

export default MediaCoverage;
