import { Media } from '../types/Media';

export const mediaCoverageTitle = 'Media Coverage';

export const mediaCoverageData: Media[] = [
  {
    title: `“Adam Langino: The Ability to Be Strategic,” Attorney At Law Magazine`,
    description: `Joy. It's not a word you often hear from personal injury attorneys when
        discussing their practices. But joy is a word that pops up often in
        conversations with Chapel Hill attorney Adam Langino. “If your lawyer
        takes joy in the practice of law, they'll be a better advocate for you,”
        he said. “They'll handle your case with enthusiasm, grace, and patience.
        They'll feel inspired by the intellectual challenges of your case and will
        take pride in finding the appropriate legal avenues through which to
        resolve it.”`,
  },
  {
    title: `“Could Parents Sue if a Child Gets COVID-19 From an Unvaccinated Teacher?
        Legal Experts Weigh In,” Fox 35 Orlando`,
    description: `“Several states have passed COVID-19 immunity laws which protect certain
        businesses and governmental entities from legal liability regarding
        COVID-19 exposure. For example, Alabama Governor Kay Ivey signed a bill in
        February 2021 that gives certain industries immunity from any injury
        caused by COVID-19 exposure, a COVID-19 vaccine or personal protection
        equipment. North Carolina-based attorney Adam Langino said that immunity
        can even apply to teachers. "Other states provide near complete immunity
        to teachers. From the outset, that makes these claims very difficult," he
        told FOX Television Stations.`,
  },
  {
    title: `“Family Sues Lantana Motel, Rehab Facility in Man's Overdose Death,” The
        Palm Beach Post`,
    description: `“According to the complaint filed in Palm Beach County Circuit Court on
        Wednesday, Tyler Aldrich admitted himself into Comprehensive Wellness
        Centers in June 2020. Three months earlier, he had completed a two-week
        program there, said attorney Adam Langino, who filed the lawsuit on behalf
        of Tyler's brother.” ““What in the world were they thinking?” Langino
        said. “This was a preventable tragedy.” The lawsuit suggests the center
        acted with money in mind "through the future profits garnered through
        (Tyler's) return to (the center) for additional drug rehabilitation
        treatment." "It seems like these centers are popping up all over the place
        in South Florida," Langino said. "They're happy to take insurance, but
        they also need to be held accountable when things go wrong, when they make
        mistakes.”`,
  },
  {
    title: `“Driver Not Criminally Charged in 2016 Crash That Killed Couple,” The Palm
        Beach Post`,
    description: `“But by the time sheriff's investigators arrived, the report said, the
        badly hurt Kelly already had been administered medication, and because of
        that, a sheriff's forensic expert later “could not say with any certainty”
        if Kelly was impaired at the time of the collision. “By the time the drug
        recognition expert got there, he (Kelly) had already been administered
        fentanyl,” Adam Langino, an attorney for the Brito family, said Thursday.
        “So there was no way to distinguish perhaps what was being caused by the
        fentanyl administered by the nurses.”`,
  },
  {
    title: `“LATEST: Suit Says Man Who Ran Red Light, Killing Couple, Was Impaired,”
        The Palm Beach Post`,
    description: `“That complaint, which attorney Adam Langino said his firm filed Thursday
        in Palm Beach County Circuit Court, says Kelly “operated his motor vehicle
        under the influence of controlled substances to the extent that his normal
        faculties were impaired.” “It is our hope that we will be able to gain a
        better understanding as to why Kelly ran the red light, ultimately killing
        Andre and Vivian, and to hold him accountable for his actions,” Da Silva,
        said Thursday in a statement. And Langino said that “the police
        investigation was able to show what happened but not why it happened. He
        added that “the family and children still do not have closure.”`,
  },
  {
    title: `Super Lawyers Q&A with Cohen Milstein's Adam J. Langino`,
    description: `“Between classical trombonist and [amateur] boxer, Adam Langino went with
        the law.” “I trained, taught and fought as an amateur boxer. I was 3-1 but
        hung up the competition gloves once I started practicing law. I still
        train and teach amateur boxing. Also, I was a classically trained trombone
        player. For many years I participated in New York state's musical
        competition and often was asked to perform at concerts.”`,
  },
  {
    title: `Adam Langino Interviewed by News4JAX - WXJT Jacksonville Regarding Lawsuit
        Against Car Seat Manufacturer`,
    description: `“The family believes that the company markets a booster seat that didn't
        properly protect their daughter in a 2014 crash that left her with
        lifelong injuries. [Adam Langino] told Action News Jax that the company
        should help pay medical bills as well as for the care that the girl will
        need for years because of injuries she suffered in the crash.”`,
  },
  {
    title: `“Family Sues Car Seat Maker After Child Injured in Crash,” News 4 JAX`,
    description: `“The family said the child was properly restrained in her booster seat.
        The family's attorney Adam Langino, said the girl was ejected from her
        seat. "Unfortunately, the young girl suffered a catastrophic injury to her
        cervical spine. We hope that we will be able to provide for her medical
        care (that) frankly, she will need for a long period of time," Langino
        said.”`,
  },
  {
    title: `“Lawsuit Against Car Seat Manufacturer Set For Trial Next Year:
        Jacksonville 5-Year-Old Was Permanently Injured in 2014 Crash,” News 4 JAX`,
    description: `“'Evenflo, due to marketing practices, invites smaller children to be
        placed in belt position booster seats when those children would be better
        served by a five-point harness safety seat,' the parent's attorney, Adam
        Langino, said.”`,
  },
  {
    title: `Martial Arts, Wrestling, & Boxing: The Proving Ground, Sports Destination
        Management`,
    description: `Adam Langino, a former amateur boxer, is president of Club 100 Charities
        in Palm Beach County, Fla.. Club 100 offers a free boxing program for
        local kids ages 10-18. “Our boxing program is free and is run in
        coordination with the Palm Beach County Sheriff's Office,” says Langino.
        “Our program is successful because of our fantastic volunteer coaches, who
        all want to improve the lives of the children they coach by increasing
        their self-confidence, their maturity and providing them a positive and
        safe outlet for their energy.”`,
  },
  {
    title: `Local Workout Group Raises $20K for Restaurants, Families in Need,
        Chapelboro.com`,
    description: `Nieman and fellow F3 Churham members Aaron Westrick, Adam Langino and Sean
        Cavanaugh, recently crafted a plan toward the end of November. When
        Langino was struck by inspiration to help local businesses and community
        members who need extra aid this holiday season, the three others also felt
        motivated. “Adam sent a text to a thread we're members of,” describes
        Nieman, “and said 'I have this idea of doing this [fundraiser],' which is
        find a way to get business to local restaurants in the form of purchasing
        a bunch of gift cards and then distributing them to families in need. Then
        he said, 'who wants to meet up in my driveway tonight to talk about it?'
        [We] three guys showed up in his driveway and the organizing committee
        formed like that.”`,
  },
];
