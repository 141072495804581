import { Award } from '../types/Award';

export const awardsTitle = 'Awards, Recognitions, & Professional Leadership';

export const awardsData: Award[] = [
  {
    title: 'AV Preeminent Rating, Martindale Hubbell',
    summary: `For more than 130 years, Martindale-Hubbell has been evaluating attorneys
        for their strong legal ability and high ethical standards through a Peer
        Review Rating system. AV Preeminent is the highest peer rating standard.
        This is given to attorneys who are ranked at the highest level of
        professional excellence for their legal expertise, communication skills,
        and ethical standards by their peers. Since 2013, Adam has held an AV
        Preeminent Rating.`,
  },
  {
    title: 'Best Lawyers in America',
    summary: `Founded in 1981, Best Lawyers is one of the original lawyer ranking
        organizations. Candidates are chosen based on feedback from their
        colleagues. Best Lawyers' methodology is designed to capture, as
        accurately as possible, the consensus opinion of leading lawyers about the
        professional abilities of their colleagues within the same geographical
        area and legal practice area. From 2020 to the present, Adam has been
        recognized as a Best Lawyer in America.`,
  },
  {
    title: 'Excellence Award, American Association of Justice',
    summary: `Founded in 1972, the American Association of Justice (formerly the
            Association of Trial Lawyers of America) is a non-profit organization
            dedicated to defending Americans' right to trial by jury. The Excellence
            Award is presented by AAJ's New Lawyers' Division to lawyers that have
            made a significant positive impact on their community. In 2014, Adam was
            awarded the Excellence Award by AAJ for his volunteer efforts in the
            community.`,
  },
  {
    title: 'Fellow, Leadership Academy, American Association for Justice',
    summary: `Founded in 1972, the American Association of Justice (formerly the
            Association of Trial Lawyers of America) is a non-profit organization
            dedicated to defending Americans' right to trial by jury. AAJ's Leadership
            Academy provides training to highly qualified and talented members, in
            particular those who are underrepresented within the association, to help
            make them more effective leaders in AAJ, in their law firms, and in their
            communities. In 2015, Adam was selected to be a fellow in AAJ's Leadership
            Academy.`,
  },
  {
    title: 'Legal Elite, Florida Trend',
    summary: `Since 2003, Florida Trend has been naming its Legal Elite. Less than 2% of
        active Florida Bar members practicing in Florida appear among the
        exclusive Florida Legal Elite. Florida Trend invites all in-state members
        of the Florida Bar to name attorneys for consideration and votes are
        tabulated using an outside vendor. In 2014, 2015, and 2018, Adam was
        recognized as a Florida Legal Elite.`,
  },
  {
    title: 'Legal Elite, Business North Carolina',
    summary: `Each year, BNC sends ballot notices to every member of the N.C. State Bar
        living in North Carolina — asking each a simple question: of the North
        Carolina lawyers whose work you have observed firsthand, whom would you
        rate among the current best? Those receiving the most votes make up the
        Legal Elite. Of the thousands practicing in the state, approximately 4%
        were selected as Elite this year.`,
  },
  {
    title: ' Rising Star, Super Lawyers (Thomson Reuters)',
    summary: `Founded in 1991, Super Lawyers selects attorneys using a patented
        multiphase selection process. Each candidate is evaluated on 12 indicators
        of peer recognition and professional achievement. Only 2.5% of attorneys
        under 40 years old are selected as Rising Stars. From 2015 to 2019, Adam
        was recognized as a Rising Star.`,
  },
  {
    title:
      'Section Chair, Products Liability, Class Action & Mass Torts Section, North Carolina Association for Justice',
    summary: `Founded in 1962, the North Carolina Association for Justice (formerly the
            North Carolina Academy of Trial Lawyers) is the third-largest trial
            lawyers association in the nation. NCAJ is a nonpartisan association of
            legal professionals dedicated to empowering a strong community of trial
            lawyers by protecting people, preventing injustice, and promoting
            fairness. NCAJ's Products Liability, Class Action & Mass Torts Section is
            comprised of leading lawyers across North Carolina. Since 2021, Adam has
            been a chair.`,
  },
  {
    title:
      'Section Chair, Products Liability Section, American Association for Justice',
    summary: `Founded in 1972, the American Association of Justice (formerly the
            Association of Trial Lawyers of America) is a non-profit organization
            dedicated to defending Americans' right to trial by jury. Its Product
            Liability Section is comprised of various leading lawyers across the U.S.
            Since 2016, Adam has been a chair.`,
  },
  {
    title: 'Super Lawyer, Super Lawyers (Thomson Reuters)',
    summary: `Founded in 1991, Super Lawyers selects attorneys using a patented
        multiphase selection process. Each candidate is evaluated on 12 indicators
        of peer recognition and professional achievement. Only 2.5% to 5% of
        attorneys are selected as Super Lawyers. From 2020 to the present, Adam
        has been recognized as a Super Lawyer.`,
  },
  {
    title: 'Top Lawyers, South Florida Legal Guide',
    summary: `Since 2002, South Florida Legal Guide publishes a listing of Top Lawyers.
        Selections are based entirely on peer nominations. The nominations are
        reviewed by South Florida Legal Guide's editorial department.
        Accomplishments and other factors that may attest to the individual's
        credentials are taken into consideration. In 2017, 2018, 2020, and 2021
        Adam was recognized as a Top Lawyer by South Florida Legal Guide.`,
  },
  {
    title: 'Trustee, Pound Civil Justice Institute',
    summary: `The Pound Civil Justice Institute is a national legal think tank that
        offers an informed, balanced view of issues affecting the U.S. civil
        justice system. Pound's programs provide opportunities for open dialogue
        among judges, legal academics, policymakers, and attorneys on issues vital
        to civil justice. Since 2015, Adam has been a trustee of the Pound Civil
        Justice Institute.`,
  },
];
