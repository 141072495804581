import React, { useState, useEffect } from 'react';

import { Publication } from '../../types/Publication';
import {
  publicationsTitle,
  publicationsData,
} from '../../data/publicationsData';

const Publications = () => {
  const [title, setTitle] = useState('');
  const [publications, setPublications] = useState<Publication[]>([]);

  useEffect(() => {
    setTitle(publicationsTitle);
    setPublications(publicationsData);
  }, []);

  return (
    <div className='flex flex-col my-5 mt-10'>
      <h1 className='py-4 text-center font-slim text-primary'>{title}</h1>

      {publications.map((pub, index) => (
        <div key={index} className='flex flex-col my-2'>
          <h3 className='dark-text head-3'>{pub.publication}</h3>
          <p className='text-md dark-text lg:pl-16'>{pub.content}</p>
        </div>
      ))}
    </div>
  );
};

export default Publications;
