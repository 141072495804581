import React, { useState, useEffect } from 'react';

import { Staff } from '../../types/Staff';
import { staffTitle, staffData } from '../../data/support-staff/staffData';

const SupportStaff = () => {
  const [title, setTitle] = useState('');
  const [staff, setStaff] = useState<Staff[]>([]);

  useEffect(() => {
    setTitle(staffTitle);
    setStaff(staffData);
  }, []);

  return (
    <div className='flex flex-col my-5 mt-10'>
      <div className='mx-auto max-w-7xl lg:px-8'>
        <div className='max-w-2xl mx-auto sm:text-center'>
          <h1 className='py-4 text-center font-slim text-primary'>{title}</h1>
        </div>

        <ul
          role='list'
          className='mx-auto -mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3'
        >
          {staff.map(sd => (
            <li className='flex flex-col gap-10 pt-12 md:flex-row' key={sd.id}>
              <img
                className='aspect-[4/5] w-1/3 flex-none rounded-2xl object-cover shadow-lg sm:w-52 sm:flex m-auto'
                src={`/images/support-staff/${sd.imageUrl}`}
                alt='Image of Pamela Casper'
              />
              <div className='flex-auto max-w-xl'>
                <h3 className='text-lg font-semibold leading-8 tracking-tight text-center text-gray-900 md:text-left'>
                  {sd.name}
                </h3>
                <p className='text-base leading-7 text-center text-gray-600 md:text-left'>
                  {sd.title}
                </p>
                <p className='mt-6 leading-7 text-md dark-text'>{sd.about}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SupportStaff;
